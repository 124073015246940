/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
// import styled from "styled-components"

// import Header from "./header"
import "./layout.css"

const Layout = ({ children, home, blackBg, about }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div
          style={{
            width: "100%",
            fontFamily: "forma-djr-text, sans-serif",
          }}
        >
          <main
            style={{
              backgroundColor: about ? "#bbb" : "white",
              minHeight: "100vh",
            }}
          >
            {children}
          </main>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
